/* Translated by Park Seok Ho */

export default {
  translation: {
    common: {
      emailOrUsername: '이메일 혹은 아이디',
      invalidEmailOrUsername: '이메일이나 사용자 이름이 유효하지 않습니다.',
      invalidPassword: '잘못된 비밀번호',
      logInToPlanka: 'Planka에 로그인',
      noInternetConnection: '인터넷에 연결되지 않음',
      pageNotFound_title: '페이지를 찾을수 없습니다.',
      password: '패스워드',
      projectManagement: '프로젝트 관리',
      serverConnectionFailed: '서버 연결 실패',
      unknownError: '알 수 없는 오류가 발생하였습니다, 나중에 다시 시도하세요.',
    },

    action: {
      logIn: '로그인',
    },
  },
};
